<template>
  <div>redirecting.....</div>
</template>

<script>
export default {
  data() {
    return {
      timeId: null,
    };
  },
  methods: {
    goNextPage() {
      var q = this.$route.query;
      var p = this.$route.params;
      this.$router.push({
        name: q.redirectName,
        query: q,
        params: p,
      });
      this.timeId = setTimeout(() => {
        if (this.$route.name === "empty") {
          console.warn("standard-empty-page");
          this.goNextPage();
        } else {
          clearTimeout(this.timeId);
          this.timeId = null;
        }
      }, 0);
    },
  },
  mounted() {
    this.goNextPage();
  },
  beforeDestroy() {
    clearTimeout(this.timeId);
    this.timeId = null;
  },
};
</script>
